<template>
  <b-overlay :show="isSubmitting" rounded="sm">
    <validation-observer ref="simpleRules">
      <b-container class="col">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
              >
                <feather-icon icon="TruckIcon" size="35" color="white" />
              </div>
              <h3 class="mr-5 text">Pick Up Point</h3>
            </div>
            <div class="w-75">
              <validation-provider
                #default="{ errors }"
                name="Origin Point"
                rules="required"
              >
                <select
                  class="form-control form-select rounded"
                  v-model="userId"
                >
                  <option value="" disabled>Select Pick Up Point</option>
                  <option
                    v-for="address in address"
                    :key="address.id"
                    :value="address"
                  >
                    {{ address.name }} | {{ address.branch.address }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
        </b-card>
        <b-card>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div
                class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
              >
                <feather-icon icon="HomeIcon" size="35" color="white" />
              </div>
              <h3 class="mr-5 text">Sender's Details</h3>
            </div>

            <div class="w-75">
              <div>
                <b-row class="mb-2">
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's First Name"
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control mr-2"
                        placeholder="First Name"
                        aria-label="First Name"
                        v-model="form.sender_first_name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Last Name"
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control mr-2"
                        placeholder="Last Name"
                        aria-label="Last Name"
                        v-model="form.sender_last_name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Phone Number"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      v-model="form.sender_phone_number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <b-row class="mb-2">
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Unit No."
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control mr-2"
                        placeholder="Unit No."
                        aria-label="Unit No."
                        v-model="form.sender_unit_number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Address Line 1"
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Address Line 1"
                        aria-label="Address Line 1"
                        v-model="form.sender_address_line_1"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="Sender's Address Line 2"
                    rules="required"
                  >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address Line 2"
                      aria-label="Address Line 2"
                      v-model="form.sender_address_line_2"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <b-row class="mb-2">
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Postcode"
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control mr-2"
                        placeholder="Postcode"
                        aria-label="Postcode"
                        v-model="form.sender_postcode"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Area"
                      rules="required"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Area"
                        aria-label="Area"
                        v-model="form.sender_area"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's State"
                      rules="required"
                    >
                      <select
                        class="form-control form-select rounded mr-2"
                        v-model="form.sender_state"
                      >
                        <option value="" disabled selected>State</option>
                        <option v-for="(state, index) in state" :key="index">
                          {{ state.state_name }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="Sender's Country"
                      rules="required"
                    >
                      <select
                        class="form-control form-select rounded"
                        v-model="form.sender_country"
                      >
                        <option value="" disabled selected>Country</option>
                        <option
                          v-for="(country, index) in country"
                          :key="index"
                        >
                          {{ country.value }}
                        </option>
                      </select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-card>
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <div
                class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
              >
                <feather-icon icon="BoxIcon" size="35" color="white" />
              </div>
              <h3 class="mr-5 text">Recipient's Bulk Orders</h3>
            </div>
            <div>
              <b-button variant="primary" @click="downloadTemplate()"
                >Download Order Template</b-button
              >
            </div>
            <div class="d-flex justify-content-end">
              <b-form-group label="Upload Order Template">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".xls,.xlsx"
                  @change="convertSheet($event)"
                  id="input"
                ></b-form-file>
              </b-form-group>
            </div>
          </div>
          <hr />
          <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
          </MyTable>
        </b-card>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <b-button
            @click="onSubmit"
            variant="gradient-primary"
            class="box-shadow-1 px-5 w-25 d-flex justify-content-center"
            >Submit</b-button
          >
        </div>
      </b-container>
    </validation-observer>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Calculating your order. Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { required } from "@validations";
import readXlsxFile from "read-excel-file";
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      fetchPrice: [
        {
          sender_postcode: "",
          recipient_postcode: "",
          order_type: "",
          size: "",
          weight: "",
          quantity: "",
        },
      ],
      dateChecker: 0,
      userId: "",
      address: { branch: { address: "" } },
      newDate: "",
      isSubmitting: false,
      pickUp: "",
      isLoading: false,
      isDeliverToday: true,
      form: {
        user_id: "",
        sender_state: "",
        sender_country: "",
        deliveries: [],
      },
      country: [
        {
          title: "Malaysia",
          value: "Malaysia",
        },
        {
          title: "Singapore",
          value: "Singapore",
        },
      ],
      state: [],
      address: [],
      rows: [],
      columns: [
        {
          label: "Shipment",
          field: "shipment",
        },
        {
          label: "Recipient's Name",
          field: "recipient_name",
        },
        {
          label: "Phone Number",
          field: "phone_number",
        },
        {
          label: "Unit Number",
          field: "unit_number",
        },
        {
          label: "Address Line 1",
          field: "address_line_1",
        },
        {
          label: "Address Line 2",
          field: "address_line_2",
        },
        {
          label: "Postcode",
          field: "postcode",
        },
        {
          label: "Area",
          field: "area",
        },
        {
          label: "State",
          field: "recipient_state",
        },
        {
          label: "Country",
          field: "country",
        },
        {
          label: "Delivery Date",
          field: "delivery_date",
        },
        {
          label: "Product Category",
          field: "product_category",
        },
        {
          label: "Size",
          field: "size",
        },
        {
          label: "Weight",
          field: "weight",
        },
        {
          label: "Quantity",
          field: "quantity",
        },
      ],
      yesno: [
        { title: "YES", value: true },
        { title: "NO", value: false },
      ],
    };
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
      this.rows = this.form.deliveries;
    }
  },
  async mounted() {
    await this.$http.get(`getFranchiser`).then((response) => {
      this.address = response.data;
    });
    this.$http.get(`state`).then((response) => {
      this.state = response.data.data;
    });
  },
  methods: {
    async downloadTemplate() {
      const response = await this.$http.get(
        `download_template/bulk_order_template`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "BulkOrderTemplateSample.xlsx");
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    onSubmit() {
      if (this.rows.length) {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            this.isSubmitting = true;
            this.form.deliveries = this.rows;
            this.form.user_id = this.userId.id;
            this.form.branch_id = this.userId.branch_id;
            this.fetchPrice = this.rows.map((row) => ({
              sender_postcode: this.form.sender_postcode,
              recipient_postcode: row.postcode,
              order_type: row.shipment,
              size: row.size,
              weight: row.weight,
              quantity: row.quantity,
            }));
            this.$http
              .post(`get_pricing_bulk_orders`, { order: this.fetchPrice })
              .then((response) => {
                if (response.data.status) {
                  this.form.pricing = response.data.order;
                  this.$router.push({
                    name: "bulk-order-cart",
                    params: { data: this.form },
                  });
                } else {
                  var errors = response.data.message;
                  for (var a in errors) {
                    var error = errors[a];
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error,
                        icon: "XIcon",
                        variant: "warning",
                      },
                    });
                  }
                }
                this.isSubmitting = false;
              });
          }
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please import bulk sheet",
            icon: "XIcon",
            variant: "warning",
          },
        });
      }
    },
    ExcelDateToJSDate(date, state, x) {
      if (typeof date === "number") {
        var month, day;
        var utc_days = Math.floor(date - 25569);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);
        if (date_info.getMonth() < 9) {
          month = "0" + (date_info.getMonth() + 1);
        } else {
          month = date_info.getMonth() + 1;
        }
        if (date_info.getDate() < 10) {
          day = "0" + date_info.getDate();
        } else {
          day = date_info.getDate();
        }
        this.newDate = `${date_info.getFullYear()}-${month}-${day}`;
      } else if (typeof date === "object") {
        var dateToSplit = JSON.stringify(date);
        dateToSplit = dateToSplit.replaceAll('"', "").split("T")[0];
        this.newDate = dateToSplit;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[yyyy-mm-dd] Date should be in date format from your excel sheet. Row ${x}`,
            icon: "XIcon",
            variant: "warning",
          },
        });
        this.dateChecker = 1;
      }
      const today = new Date();
      const selectedDate = new Date(this.newDate);
      if (selectedDate <= today) {
        const formattedSelectedDate = selectedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Please input the correct date. ${formattedSelectedDate}. Row ${x}`,
            icon: "XIcon",
            variant: "warning",
          },
        });
        this.dateChecker = 1;
      }
      let found = false;
      this.state.forEach((stateObj) => {
        if (stateObj.state_name.toLowerCase() === state.toLowerCase()) {
          found = true;
        }
      });
      if (!found) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Please input the correct state. "${state}". Row ${x}`,
            icon: "XIcon",
            variant: "warning",
          },
        });
        this.dateChecker = 1;
      }
    },
    convertSheet(e) {
      this.dateChecker = 0;
      this.rows.length = 0;
      let tempRows = [];
      readXlsxFile(e.target.files[0]).then((rows) => {
        if (
          rows[0][0] == "Shipment" &&
          rows[0][1] == "Recipient’s name" &&
          rows[0][2] == "Phone Number" &&
          rows[0][3] == "Unit Number" &&
          rows[0][4] == "Address Line 1" &&
          rows[0][5] == "Address Line 2" &&
          rows[0][6] == "Postcode" &&
          rows[0][7] == "Area" &&
          rows[0][8] == "State" &&
          rows[0][9] == "Country" &&
          rows[0][10] == "Delivery Date" &&
          rows[0][11] == "Product Category" &&
          rows[0][12] == "Size" &&
          rows[0][13] == "Weight" &&
          rows[0][14] == "Quantity"
        ) {
          for (let x = 1; x < rows.length; x++) {
            if (
              (rows[x][0] || "").toLowerCase() == "Franchise" ||
              ((rows[x][0] || "").toLowerCase() == "singapore" &&
                rows[x][1] != "" &&
                rows[x][2] != "" &&
                rows[x][3] != "" &&
                rows[x][4] != "" &&
                rows[x][5] != "" &&
                rows[x][6] != "" &&
                rows[x][7] != "" &&
                rows[x][8] != "" &&
                rows[x][9] != "" &&
                rows[x][10] != "" &&
                rows[x][11] != "" &&
                rows[x][12] != "" &&
                rows[x][13] != "" &&
                rows[x][14] != "")
            ) {
              this.ExcelDateToJSDate(rows[x][10], rows[x][8], x);
              if (this.dateChecker != 1) {
                tempRows.push({
                  shipment: rows[x][0],
                  recipient_name: rows[x][1],
                  phone_number: rows[x][2],
                  unit_number: rows[x][3],
                  address_line_1: rows[x][4],
                  address_line_2: rows[x][5],
                  postcode: rows[x][6],
                  area: rows[x][7],
                  recipient_state: rows[x][8],
                  country: rows[x][9],
                  delivery_date: this.newDate,
                  product_category: rows[x][11],
                  size: rows[x][12],
                  weight: rows[x][13],
                  quantity: rows[x][14],
                });
              }
            } else {
              this.dateChecker = 1;
              x = rows.length;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Please fill up all the fields required correctly. Row ${
                    x - 1
                  }`,
                  icon: "XIcon",
                  variant: "warning",
                },
              });
            }
          }
        } else {
          this.dateChecker = 1;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Please import correct template sheet",
              icon: "XIcon",
              variant: "warning",
            },
          });
        }
        if (this.dateChecker != 1) {
          this.rows = tempRows;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}

.custom-file-label {
  font-size: 14px;
}
</style>
